<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="register-wrap d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Регистрация
          </b-card-title>
          <!-- <b-card-text class="mb-2">
          </b-card-text> -->

          <!-- form -->
          <validation-observer
            :key="componentKey"
            ref="registerForm"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Фамилия"
                label-for="last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Фамилия"
                  rules="required"
                >
                  <b-form-input
                    id="last_name"
                    v-model="formData.last_name"
                    :state="errors.length > 0 ? false:null"
                    name="last_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- first_name -->
              <b-form-group
                label="Имя"
                label-for="first_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Имя"
                  rules="required"
                >
                  <b-form-input
                    id="first_name"
                    v-model="formData.first_name"
                    :state="errors.length > 0 ? false:null"
                    name="first_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Отчество"
                label-for="surname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="surname"
                >
                  <b-form-input
                    id="surname"
                    v-model="formData.surname"
                    name="surname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="E-mail"
                label-for="email"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                >
                  <b-form-input
                    id="email"
                    v-model="formData.email"
                    name="email"
                    placeholder="user@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Телефон"
                label-for="phone"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Телефон"
                >
                  <b-form-input
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    v-mask="'+7(###) ###-####'"
                    placeholder="+7(987) 654-3210"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="formData.status"
                    :state="errors.length > 0 ? false:null"
                    name="checkbox-1"
                  >
                    Я согласен с
                    <b-link :href="server + '/confidence'" target="blank">политикой конфиденциальности и условиями</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                :disabled="loading"
                type="submit"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-50"
                  />
                  <span>Зарегистрироваться</span>
                </div>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Уже есть аккаунт? </span>
            <b-link :to="{ name:'login', query: { ...$route.query } }">
              <span>Войти</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Register v1 -->
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BCardTitle, BCardText, BForm,
  BRow, BCol, BImg,
  BButton, BFormInput, BFormGroup, BFormCheckbox,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    // BSV
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BRow,
    BCol,
    BImg,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup(_, { root }) {
    const server = process.env.VUE_APP_SERVER
    const registerForm = ref(null)

    const componentKey = ref(0)

    const formData = ref({})
    const loading = ref(false)

    const sendData = {
      fio: '',
      email: '',
      phone: '',
      login: '',
      // role: 'admin',
    }

    const validationForm = () => {
      loading.value = true
      registerForm.value.validate().then(success => {
        if (success) {
          sendData.fio = `${formData.value.last_name || ''} ${formData.value.first_name || ''} ${formData.value.surname || ''}`.trim()
          sendData.phone = formData.value.phone
          sendData.email = formData.value.email
          sendData.login = formData.value.email

          useJwt.register(sendData).then(response => {
            const { data } = response
            if (data) {
              loading.value = false
              formData.value = {
                fio: '',
                email: '',
                phone: '',
                login: '',
              }

              componentKey.value += 1

              root.$swal({
                icon: 'success',
                html: data.message,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            const { data } = error.response
            root.$swal({
              icon: 'error',
              html: data.message,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            loading.value = false
            registerForm.value.setErrors(error)
          })
        } else {
          loading.value = false
        }
      })
    }

    return {
      server,
      loading,
      componentKey,
      registerForm,
      formData,
      validationForm,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
